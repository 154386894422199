import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const UsingDSG = () => (
  <Layout>
    <Seo title="Terms of Service" />
    <h1>Terms of Service</h1>

    <p>
      By signing up to Runder, these terms will automatically apply to you - you should make sure therefore that you read them carefully before signing in.
      You're not allowed to copy or modify Runder, any part of Runder, or our trademarks in any way.
      You're not allowed to attempt to extract the source code of Runder, and you also shouldn't try to translate Runder into other languages or make derivative versions.
      Runder itself, and all the trademarks, copyright, database rights, and other intellectual property rights related to it, still belong to us.
    </p>
    <p>
      We are committed to ensuring that Runder is as useful and efficient as possible.
      For that reason, we reserve the right to make changes to Runder at any time and for any reason.
    </p>
    <p>
      Runder stores and processes personal data that you have provided to us, to provide the service.
      It's your responsibility to keep your phone and access to Runder secure.
      We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device.
      It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone's security features and it could mean that the Runder app won't work properly or at all.
    </p>
    <div>
      <p>
        Runder does use third-party services that declare their Terms of Service.
      </p>
      <p>
        Link to Terms of Service of third-party service providers used by Runder
      </p>
      <ul>
        <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
        <li><a href="https://firebase.google.com/terms/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
      </ul>
    </div>
    <p>
      You should be aware that there are certain things that we will not take responsibility for.
      Certain functions of Runder will require Runder to have an active internet connection.
      The connection can be Wi-Fi or provided by your mobile network provider, but we cannot take responsibility for Runder not working at full functionality if you don't have access to Wi-Fi, and you don't have any of your data allowance left.
    </p>
    <p>
      If you're using Runder outside of an area with Wi-Fi, you should remember that the terms of the agreement with your mobile network provider will still apply.
      As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing Runder, or other third-party charges.
      In using Runder, you're accepting responsibility for any such charges, including roaming data charges if you use Runder outside of your home territory (i.e. region or country) without turning off data roaming.
      If you are not the bill payer for the device on which you're using Runder, please be aware that we assume that you have received permission from the bill payer for using Runder.
    </p>
    <p>
      Along the same lines, we cannot always take responsibility for the way you use Runder.
      Please be careful of the surroundings when using Runder.
      We cannot take responsibility for trafic accidents and device damages.
      You need to make sure that your device stays charged - if it runs out of battery and you can't turn it on to avail the service, we cannot accept responsibility.
    </p>
    <p>
      With respect to our responsibility for your use of Runder, when you're using Runder, it's important to bear in mind that although we endeavor to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you.
      We accept no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of Runder.
    </p>
    <p>
      At some point, we may wish to update Runder.
      Runder is currently available on Android &amp; iOS - the requirements for the both systems(and for any additional systems we decide to extend the availability of Runder to) may change, and you'll need to download the updates if you want to keep using Runder.
      We do not promise that it will always update Runder so that it is relevant to you and/or works with the Android &amp; iOS version that you have installed on your device.
      However, you promise to always accept updates to Runderlication when offered to you, we may also wish to stop providing Runder, and may terminate use of it at any time without giving notice of termination to you.
      Unless we tell you otherwise, upon any termination,
      (a) the rights and licenses granted to you in these terms will end;
      (b) you must stop using Runder, and (if needed) delete it from your device.
    </p>
    <p>
      <strong>Changes to This Terms of Service</strong>
    </p>
    <p>
      We may update our Terms of Service from time to time.
      Thus, you are advised to review this page periodically for any changes.
    </p>
    <p>
      These Terms of Service are effective as of 2022-02-21.
    </p>
    <p>
      <strong>Contact Us</strong>
    </p>
    <p>
      If you have any questions or suggestions about our Terms of Service, do not hesitate to contact us at contact@shundroid.com.
    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default UsingDSG
